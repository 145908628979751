import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataserviceService} from '../dataservice.service';
import {User} from '../classes/User';
import notify from 'devextreme/ui/notify';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user = {email: '', password: ''};
  salvarSenha: boolean = false;

  constructor(public route: Router, private activeRoute:ActivatedRoute, private ds:DataserviceService) {

      this.ds.checkVersao();

  }

  submit(){
    this.ds.http.post(this.ds.server + "login/signIn", this.user, this.ds.getHeaders())
        .subscribe((data: any) => {

            console.log(data);
            if (data.erro === true){
                notify("Usuário ou senha inválido", "error", 1500);
            }else {
                this.ds.profile = data[0];
                this.ds.permissao = JSON.parse(this.ds.profile.PERMISSAO);
                this.route.navigate(['main']);
                localStorage.setItem('key', this.ds.profile.ID.toString());

                this.ds.checkVersao();

            }
        }, (err)=>{
            notify("Usuário ou senha inválido", "error", 1500);
        })
  }

  ngOnInit() {
  }

}
