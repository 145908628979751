import { Component, OnInit, ElementRef } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { Router } from '@angular/router';
import Chart from 'chart.js';
import {DataserviceService} from '../../dataservice.service';
import notify from 'devextreme/ui/notify';
import {Tabela} from '../../classes/Tabela';



declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export const ROUT: RouteInfo[] = [
    { path: '/main/principal', title: 'Principal',icon:'design_app', class: 'principal' },
    { path: '/main/controle-veiculos', title: 'Controle de Veículos',  icon:'shopping_delivery-fast', class: 'controle-veiculos' },
    { path: '/main/tabelas', title: 'Tabelas Gerais',  icon:'education_paper', class: 'tabelas' },
    { path: '/main/seguro', title: 'Tabela Seguro',  icon:'design_bullet-list-67', class: 'seguro' },
    { path: '/main/configuracao', title: 'Configurações', icon: 'ui-1_settings-gear-63', class:'configuracao'}
];

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
    listTitles: any[];
    location: Location;
    mobile_menu_visible: any = 0;
    private toggleButton: any;
    private sidebarVisible: boolean;

    public isCollapsed = false;

    menuItems: any[];
    menu = [];

    resenha: string = '';
    senha: string = '';
    senhaAnterior: string = '';
    login: any = {email: "", password: "", oldPass: ""};
    popupSenhaVisible = false;


    constructor(location: Location,  private element: ElementRef, private router: Router, public ds:DataserviceService) {

        ds.verifSignIn();

        this.location = location;
        this.sidebarVisible = true;

        this.ds.http.get(this.ds.server + "tabelas/telas", this.ds.getHeaders())
            .subscribe((data:any) => {
                this.listTitles = data;
            })
    }

    ngOnInit(){
        //this.listTitles = this.ds.telas;// ROUTES.filter(listTitle => listTitle);

        //const navbar: HTMLElement = this.element.nativeElement;
        // this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        // this.router.events.subscribe((event) => {
        //     this.sidebarClose();
        //     var $layer: any = document.getElementsByClassName('close-layer')[0];
        //     if ($layer) {
        //         $layer.remove();
        //         this.mobile_menu_visible = 0;
        //     }
        // });

        this.loadMenu();

    }

    loadMenu(){

        let idUser = localStorage.getItem('key');

        this.menuItems = ROUT.filter(menuItem => menuItem);

        this.ds.http.get(this.ds.server + "tabelas/telas/", this.ds.getHeaders())
            .subscribe((data:any)=>{

                let dados: Tabela[] = data;

                dados.forEach(x => {

                    if(x.PRINCIPAL && this.ds.getPermissaoMenuPrincipal(x.ID).VISUALIZAR){
                        this.menu.push(x);
                    }
                })
            })

    }

    collapse(){
        // this.isCollapsed = !this.isCollapsed;
        // const navbar = document.getElementsByTagName('nav')[0];
        // console.log(navbar);
        // if (!this.isCollapsed) {
        //     navbar.classList.remove('navbar-transparent');
        //     navbar.classList.add('bg-white');
        // }else{
        //     navbar.classList.add('navbar-transparent');
        //     navbar.classList.remove('bg-white');
        // }

    }

    sidebarOpen() {
        // const toggleButton = this.toggleButton;
        // const mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel')[0];
        // const html = document.getElementsByTagName('html')[0];
        // if (window.innerWidth < 991) {
        //     mainPanel.style.position = 'fixed';
        // }
        //
        // setTimeout(function(){
        //     toggleButton.classList.add('toggled');
        // }, 500);
        //
        // html.classList.add('nav-open');
        //
        // this.sidebarVisible = true;
    };
    sidebarClose() {
       // const html = document.getElementsByTagName('html')[0];
        // this.toggleButton.classList.remove('toggled');
        // const mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel')[0];
        //
        // if (window.innerWidth < 991) {
        //     setTimeout(function(){
        //         mainPanel.style.position = '';
        //     }, 500);
        // }
        // this.sidebarVisible = false;
        // html.classList.remove('nav-open');
    };

    logout(){
        this.router.navigate([""]).then((data)=>{
            localStorage.removeItem("key");
            this.ds.profile = null;
            this.ds.permissao = null;
        })
    }

    sidebarToggle() {
        // // const toggleButton = this.toggleButton;
        // // const html = document.getElementsByTagName('html')[0];
        // var $toggle = document.getElementsByClassName('navbar-toggler')[0];
        //
        // if (this.sidebarVisible === false) {
        //     this.sidebarOpen();
        // } else {
        //     this.sidebarClose();
        // }
        // const html = document.getElementsByTagName('html')[0];
        //
        // if (this.mobile_menu_visible == 1) {
        //     // $('html').removeClass('nav-open');
        //     html.classList.remove('nav-open');
        //     if ($layer) {
        //         $layer.remove();
        //     }
        //     setTimeout(function() {
        //         $toggle.classList.remove('toggled');
        //     }, 400);
        //
        //     this.mobile_menu_visible = 0;
        // } else {
        //     setTimeout(function() {
        //         $toggle.classList.add('toggled');
        //     }, 430);
        //
        //     var $layer = document.createElement('div');
        //     $layer.setAttribute('class', 'close-layer');
        //
        //
        //     if (html.querySelectorAll('.main-panel')) {
        //         document.getElementsByClassName('main-panel')[0].appendChild($layer);
        //     }else if (html.classList.contains('off-canvas-sidebar')) {
        //         document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
        //     }
        //
        //     setTimeout(function() {
        //         $layer.classList.add('visible');
        //     }, 100);
        //
        //     $layer.onclick = function() { //asign a function
        //         html.classList.remove('nav-open');
        //         this.mobile_menu_visible = 0;
        //         $layer.classList.remove('visible');
        //         setTimeout(function() {
        //             $layer.remove();
        //             $toggle.classList.remove('toggled');
        //         }, 400);
        //     }.bind(this);
        //
        //     html.classList.add('nav-open');
        //     this.mobile_menu_visible = 1;

     //   }
    };

    getTitle(){

        // var titlee = this.location.prepareExternalUrl(this.location.path());
        //
        // if(titlee.charAt(0) === '#'){
        //     titlee = titlee.slice( 2 );
        // }
        //
        // titlee = titlee.split('/main/').pop();
        //
        // for(var item = 0; item < this.listTitles.lenght; item++){
        //     if(this.listTitles[item].NOME === titlee){
        //         return this.listTitles[item].TITLE;
        //     }
        // }

        return 'Sistemas Mac';
    }

    //TODO: Metodos para alterar Senha
    alterarSenha(){
        this.popupSenhaVisible = true;
    }

    enviarSenhaAlterada() {

        if (this.senhaAnterior && this.senha == this.resenha){

            if (this.senha.length < 6){
                console.log(this.senha.length);
                notify("Senha precisar ter no mínimo 6 caracteres", "error", 1000);
                return;
            }

            this.login.oldPass = this.senhaAnterior;
            this.login.password = this.senha;
            this.login.email = this.ds.profile.EMAIL;

            this.ds.http.post(`${this.ds.server}login/resetPassword`, this.login, this.ds.getHeaders())
                .subscribe((data:any) => {

                    if (data && data.code == 'auth/weak-password'){
                        notify("Senha precisar ter no mínimo 6 caracteres", "error", 1000);
                    }else if(data && data.code == 'auth/wrong-password'){
                        notify("Sua senha anterior está incorreta", "error", 1000);
                    }else{
                        if (data[0].atualiza_senha == 1){
                            notify("Senha alterada com sucesso", "success", 1000);
                            this.popupSenhaVisible = false;
                        }else{
                            notify("Senha anterior inválida.", "error ", 1000);
                        }
                    }
                });
        }else{
            notify("As senhas não estão iguais.", "error", 1000);
        }
    }

}
