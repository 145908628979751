import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import notify from 'devextreme/ui/notify';
import {DataserviceService} from '../../dataservice.service';



@Component({
  selector: 'app-login-cliente',
  templateUrl: './login-cliente.component.html',
  styleUrls: ['./login-cliente.component.scss']
})
export class LoginClienteComponent implements OnInit {

  user = {email: '', password: ''};
  salvarSenha: boolean = false;

  constructor(public route: Router, private activeRoute:ActivatedRoute, private ds:DataserviceService) { }

  submit(){
    this.ds.http.post(this.ds.server + "login/clienteLogin", this.user, this.ds.getHeaders())
        .subscribe((data:any) => {

          if(data.length > 0) {
            this.ds.profile_cliente = data[0];

            localStorage.setItem('clientekey', this.ds.profile_cliente.CLIENTE.toString());

            if (this.salvarSenha) {
              localStorage.setItem('loginID', this.ds.profile.ID.toString());
            }

            this.ds.http.get(`${this.ds.server}tabelas/cliente/${this.ds.profile_cliente.CLIENTE}`)
                .subscribe((cliente: any)=>{
                  this.ds.cliente = cliente[0];
                  this.route.navigate(['main']);
                })

          }else{
            notify("Usuário ou senha inválido", "error", 1500);
          }
        }, (err)=>{
          notify("Usuário ou senha inválido", "error", 1500);
        })
  }

  ngOnInit() {
  }

}
