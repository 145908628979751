import { Component} from '@angular/core';
import {DataserviceService} from './dataservice.service';
import config from 'devextreme/core/config';
import { locale, loadMessages, formatMessage } from 'devextreme/localization';
import 'devextreme-intl';
import 'devextreme-intl';
import ptMessages from "devextreme/localization/messages/pt.json";

declare var require: any

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

   constructor(public ds: DataserviceService){

   }

}
