export class Configuracao{
    ID: number;
    FILIAL: number;
    COMISSAO_VENDEDOR: number;
    COMISSAO_MOTORISTA: number;
    IMPOSTO: number;
    CUSTO_FINANCEIRO: number;
    USUARIO_CORRENTE: boolean;
    OPERADOR: number;
    DATALT: Date;
    OBJNEW: string;
    OBJOLD: string
}

export class Filtro_Controle{
    filtroControle: any = {};
    filtroExcel: any = {};
    filtroArm: any = {};
    filtroArmExcel: any = {};
    filtroFaturamento: any = {};
    filtroFaturamentoExcel: any = {};
}
