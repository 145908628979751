export class Permissao{
    ID: number;
    ID_HEAD: number;
    ID_TABELA: number;
    NOME: string;
    ALLOW_VISUALIZAR: boolean;
    VISUALIZAR: boolean;
    ALLOW_LER: boolean;
    LER: boolean;
    ALLOW_ESCREVER: boolean;
    ESCREVER: boolean;
    ALLOW_CRIAR: boolean;
    CRIAR: boolean;
    ALLOW_REMOVER: boolean;
    REMOVER: boolean;
}
