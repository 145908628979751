export class User {
    ID: number;
    OWNER: boolean;
    ATIVO: boolean;
    NOME: string;
    EMAIL: string;
    UID: string;
    ENDERECO: string;
    BAIRRO: string;
    CIDADE: number;
    CPF: string;
    IDENTIDADE: string;
    TELEFONE: string;
    CARGO: number;
    REMEMBER: boolean;
    MESTRE: boolean;
    PERMISSAO: string;
    FILIAL: number;
    DATA_NASCIMENTO: Date;
    SALVAFILTRO: boolean;
    SENHA: string;

    OPERADOR: number;
    DATALT: Date;
    OBJNEW: string;
    OBJOLD: string;


}

export class ANEXO_USR{
    ID: number;
    USERID: number;
    DESCRICAO: string;
    ANEXOS: string;
    DATA: Date;

    OPERADOR: number;
    DATALT: Date;
    OBJNEW: string;
    OBJOLD: string

}