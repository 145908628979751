import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {forwardRef, NgModule} from '@angular/core';
import {FormsModule, NG_VALUE_ACCESSOR} from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { LoginComponent } from './login/login.component';
import {FormioModule} from 'angular-formio';
import {BrowserModule} from '@angular/platform-browser';
import {DataserviceService} from './dataservice.service';
import {HttpClientModule} from '@angular/common/http';
import {User} from './classes/User';
import { MainComponent } from './main/main.component';
import {
    DevExtremeModule,
    DxAutocompleteModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxDropDownBoxModule, DxFileUploaderModule, DxFormModule, DxLoadIndicatorModule, DxLoadPanelModule,
    DxNumberBoxModule, DxPopupModule, DxResponsiveBoxModule,
    DxTabPanelModule, DxTextAreaModule,
    DxTextBoxModule, DxValidatorModule
} from 'devextreme-angular';
import {loadMessages, locale} from 'devextreme/localization';
import 'devextreme-intl';
import { CloudinaryModule } from '@cloudinary/angular-5.x';
import * as  Cloudinary from 'cloudinary-core';
import {FileUploadModule} from 'ng2-file-upload';
import {Ng2CloudinaryModule} from 'ng2-cloudinary';
import config from 'devextreme/core/config';
import DevExpress from 'devextreme/bundles/dx.all';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';

import ptMessages from "devextreme/localization/messages/pt.json";
import {Filtro_Controle} from './classes/Configuracao';
import { LoginClienteComponent } from './cliente/login-cliente/login-cliente.component';
import { MainClienteComponent } from './cliente/main-cliente/main-cliente.component';
import {ReportComponent} from './report/report.component';
import { RelatoriosComponent } from './relatorios/relatorios.component';
import { RelatorioViewComponent } from './relatorios/relatorio-view/relatorio-view.component';

declare var require: any

let messagesPt = require("devextreme/localization/messages/pt.json");

// Specifying a currency globally
loadMessages(messagesPt);


console.log("lingua", navigator.language);
locale("pt-br");
config({ defaultCurrency: 'BRL'});


@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        HttpModule,
        ComponentsModule,
        BrowserModule,
        RouterModule,
        SweetAlert2Module.forRoot(),
        AppRoutingModule,
        DxTabPanelModule,
        NgbModule.forRoot(),
        HttpClientModule,
        DxDataGridModule,
        DxCheckBoxModule,
        FileUploadModule,
        Ng2CloudinaryModule,
        DxDataGridModule,
        DxDropDownBoxModule, DxTabPanelModule, DxTextBoxModule, DxNumberBoxModule, DxButtonModule, DxValidatorModule, DxCheckBoxModule,
        DxPopupModule, DxFileUploaderModule, DxTextAreaModule, DxResponsiveBoxModule, DxAutocompleteModule, DxLoadPanelModule, DxLoadIndicatorModule,
        DxFormModule,
        CloudinaryModule.forRoot(Cloudinary, {
            cloud_name: 'genesisoft', api_key: '959962199234158',
            upload_preset: 'za3hxw6e'
        }), DevExtremeModule,
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        LoginComponent,
        LoginClienteComponent,
        MainClienteComponent
    ],
    providers: [
        DataserviceService, User, Filtro_Controle, {provide: LocationStrategy, useClass: HashLocationStrategy}
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
