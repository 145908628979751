import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import {DxButtonModule, DxPopupModule, DxTextBoxModule} from 'devextreme-angular';
import {FormsModule} from '@angular/forms';
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component';
import {ConfirmationDialogService} from './confirmation-dialog/confirmation-dialog.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
      FormsModule,
      DxPopupModule,
      DxTextBoxModule,
      DxButtonModule,
    NgbModule
  ],
  declarations: [
      FooterComponent,
      NavbarComponent,
      SidebarComponent,
      ConfirmationDialogComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent
  ],
    providers: [ConfirmationDialogService],
    entryComponents: [ConfirmationDialogComponent]
})
export class ComponentsModule { }
