import { Component, OnInit } from '@angular/core';
import {Tabela} from '../../classes/Tabela';
import {DataserviceService} from '../../dataservice.service';
import {d} from '@angular/core/src/render3';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/main/principal', title: 'Principal',icon:'design_app', class: 'principal' },
    { path: '/main/controle-veiculos', title: 'Controle de Veículos',  icon:'shopping_delivery-fast', class: 'controle-veiculos' },
    { path: '/main/tabelas', title: 'Tabelas Gerais',  icon:'education_paper', class: 'tabelas' },
    { path: '/main/seguro', title: 'Tabela Seguro',  icon:'design_bullet-list-67', class: 'seguro' },
    { path: '/main/configuracao', title: 'Configurações', icon: 'ui-1_settings-gear-63', class:'configuracao'}
];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
    menuItems: any[];
    menu = [];

    constructor(private ds: DataserviceService) { }

    ngOnInit() {

        let idUser = localStorage.getItem('key');

        this.menuItems = ROUTES.filter(menuItem => menuItem);

        this.ds.http.get(this.ds.server + "tabelas/telas/", this.ds.getHeaders())
            .subscribe((data:any)=>{

                let dados: Tabela[] = data;

                dados.forEach(x => {

                    if(x.PRINCIPAL && this.ds.getPermissaoMenuPrincipal(x.ID).VISUALIZAR){
                        this.menu.push(x);
                    }
                })
            })

        // this.ds.http.get(this.ds.server + "users/listaTabelaByUser/?id="+idUser, this.ds.getHeaders())
        //     .subscribe((data:any)=>{
        //         data.forEach(x => {
        //             if(x.PRINCIPAL){
        //                 this.menu.push(x);
        //             }
        //         })
        //     })


    }
    isMobileMenu() {
        if ( window.innerWidth > 991) {
            return false;
        }
        return true;
    };
}
