import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import {LoginComponent} from './login/login.component';
import {LoginClienteComponent} from './cliente/login-cliente/login-cliente.component';

const routes: Routes =[
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full',
    }, {
        path: 'main',
        component: AdminLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
            }]},
    { path: 'login', component: LoginComponent},
    { path: 'logincliente', component: LoginClienteComponent},
    {
        path: '**',
        redirectTo: '/login'
    }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes)
    ],
    exports: [
    ],
})
export class AppRoutingModule { }
