import { Injectable } from '@angular/core';
import {HttpClient, HttpUserEvent} from '@angular/common/http';
import 'rxjs/Rx';
import {HttpHeaders} from '@angular/common/http';
import {Observable, Subscription} from 'rxjs';
import {User} from './classes/User';
import {Seguro} from './classes/Seguro';
import router from 'devextreme/framework/router';
import {Router} from '@angular/router';
import {Configuracao, Filtro_Controle} from './classes/Configuracao';
import {Cidades} from './classes/Cidades';
import notify from 'devextreme/ui/notify';
import {Tabela} from './classes/Tabela';
import {Permissao} from './classes/Permissao';
import {Estacionamento, Setor_Estacionamento} from './classes/Estacionamento';
import {Armazenagens} from './classes/Armazenagens';
import {Cliente, Sistema_Cliente} from './classes/Cliente';
import {Taxa} from './classes/Taxa';
import {FAIXA_PORCENTAGEM_ADM} from './classes/FAIXA_PORCENTAGEM_ADM';
import {Response} from '@angular/http';
import {map} from 'rxjs/operators';

declare var Stimulsoft: any;

@Injectable()
export class DataserviceService {

    viewer: any = new Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);
    report: any = new Stimulsoft.Report.StiReport();
    public filtroRelatorio = [];

    public server: string;
    public profile: User
    public profile_cliente: Sistema_Cliente;
    public comissao_vendedor: number = 3;
    public comissao_motorista: number = 4;
    public imposto: number = 2;
    public custo_financeiro = 1;
    public imposto_cotacao = 11;
    public seguros: Seguro[];
    public cidades: Cidades[];
    public telas: Tabela[];
    public permissao: Permissao[];
    public estacionamento: Estacionamento[];
    public cliente: Cliente;
    public taxa: Taxa[];
    public faixa: FAIXA_PORCENTAGEM_ADM[];

    public configuracao: Configuracao;

    public versao: any = {numero: "1.3.1", build: 1, lancamento: "17/11/2019"};

    demo: boolean = false;

    public carregando: boolean = false;

    constructor(public http: HttpClient, private routeNav: Router, public filtros: Filtro_Controle) {

        this.server =  this.demo == true ? "http://localhost:3000/" : "http://sistemasmac.sa-east-1.elasticbeanstalk.com/"

        this.loginSaved();
        this.loadConfig();
        //this.loginClienteSaved();
        //this.atualizaNovaPermissao();
    }

    loginClienteSaved(){
        var uid = localStorage.getItem("loginID");
        if (uid){
            this.http.get(this.server + "login/clienteLogin/"+uid, this.getHeaders())
                .subscribe((data) => {
                    this.profile_cliente.CLIENTE = data[0];

                    this.routeNav.navigate(['main']);

                    localStorage.setItem('clientekey', this.profile_cliente.CLIENTE.toString());

                    this.http.get(`${this.server}tabelas/cliente/${this.profile_cliente.CLIENTE}`)
                        .subscribe((cliente: any)=>{
                            this.cliente = cliente[0];
                        });

                    this.checkVersao();

                });
        }else{
            this.routeNav.navigate([""]);
        }
    }

    public checkVersao(){
        this.http.get(`${this.server}login/versao/`, this.getHeaders())
            .subscribe((dataVer: any)=>{
                let versao_online = dataVer

                console.log(this.versao);

                if (this.versao.numero != versao_online.numero){
                    notify(` Você esta usando uma versão antiga do sistemas, limpe os dados de navegação e recarregue a página. 
                  Versão Correta: ${versao_online.numero} Build: ${versao_online.build} Lançamento: ${versao_online.lancamento}` ,
                        "info", 3000);
                    this.routeNav.navigate([""]);
                }else if (this.versao.build != versao_online.build){
                    notify(` Você esta usando uma versão antiga do sistemas, limpe os dados de navegação e recarregue a página. 
                  Versão Correta: ${versao_online.numero} Build: ${versao_online.build} Lançamento: ${versao_online.lancamento}` ,
                        "info", 3000);
                    this.routeNav.navigate([""]);
                }else if (this.versao.lancamento != versao_online.lancamento){
                    notify(` Você esta usando uma versão antiga do sistemas, limpe os dados de navegação e recarregue a página. 
                  Versão Correta: ${versao_online.numero} Build: ${versao_online.build} Lançamento: ${versao_online.lancamento}` ,
                        "info", 3000);
                    this.routeNav.navigate([""]);
                }

            });
    }

    async loginSaved(){
        var uid = localStorage.getItem("key");
        if (uid){
            this.profile = await this.http.get<User>(this.server + "users/listaId/?id="+uid, this.getHeaders())
                .pipe(map(user => user[0]))
                .toPromise();

            this.permissao = JSON.parse(this.profile.PERMISSAO);
        }else{
            this.routeNav.navigate([""]);
        }
    }

    public verifSignIn(){
        if (!this.profile){
            this.routeNav.navigate([""]);
        }
    }

    public getHeaders(): any{

        //console.log(this.user);
        let myHeaders = new HttpHeaders();
        myHeaders.append('Content-type', 'application/json');

        const httpOptions = {
            headers: myHeaders,
            responseType: 'json'
        }

        return httpOptions;
    }

    public getFipeHeades():any{


        //console.log(this.user);
        let myHeaders = new HttpHeaders();
        myHeaders.append('Host', 'veiculos.fipe.org.br');
        myHeaders.append('Referer', 'http://veiculos.fipe.org.br');
        myHeaders.append('Content-type', 'application/json');
        myHeaders.append('Access-Control-Allow-Origin', '*');


        const httpOptions = {
            headers: myHeaders,
            responseType: 'json'
        }

        return httpOptions;
    }

    async loadConfig(){

        this.carregando = true;

        // @ts-ignore
        this.telas = await this.http.get<Tabela[]>(this.server + "tabelas/telas", this.getHeaders())
            .toPromise();

        // @ts-ignore
        this.seguros = await this.http.get<Seguro[]>(this.server + "tabelas/seguro", this.getHeaders())
            .toPromise();

        this.configuracao = await this.http.get<Configuracao>(this.server + "tabelas/configuracao", this.getHeaders())
            .pipe(map(configuracao => configuracao[0]))
            .toPromise();

        // @ts-ignore
        this.cidades = await this.http.get<Cidades[]>(this.server + "tabelas/cidade", this.getHeaders())
            .toPromise();


        // @ts-ignore
        this.estacionamento = await this.http.get<Estacionamento[]>(this.server + "tabelas/estacionamento", this.getHeaders())
            .toPromise();

        // @ts-ignore
        this.taxa = await this.http.get<Taxa[]>(this.server + "tabelas/taxa", this.getHeaders())
            .toPromise();

        // @ts-ignore
        this.faixa = await this.http.get<FAIXA_PORCENTAGEM_ADM[]>(this.server + "tabelas/faixa", this.getHeaders())
            .toPromise();

        this.carregando = false;

    }

    findSeguro(DE:number, PARA: number):number{

        if(DE == null || PARA == null)
            return 0;

        return this.seguros && this.seguros.find(x => x.DE == DE && x.PARA == PARA).PORCENTAGEM || 0;
    }

    verRelatorio(relatorio: string){

        this.report.http.request(relatorio).subscribe((data: Response) => {

            this.report.loadDocument(data.json());
            this.viewer.report = this.report;
            this.viewer.renderHtml('viewer');

        });
    }

    insert_table(tabela: string, dados: any, path: string):Observable<ArrayBuffer>{

        //dados.OPERADOR = this.profile.ID;
        //dados.OBJOLD = "{}"
        //dados.OBJNEW = JSON.stringify(dados);

        var campos = Object.keys(dados);

        var valores: any;
        valores = (<any>Object).values(dados);

        var obj = {
            tabela: tabela,
            campos: campos,
            valores: valores
        };

        return this.http.post(`${this.server + path}`, obj, this.getHeaders());
    }

    insert_table_extra(tabela: string, dados: any, dados_2:any, path: string):Observable<ArrayBuffer>{

        //dados.OPERADOR = this.profile.ID;
        //dados.OBJOLD = "{}"
        //dados.OBJNEW = JSON.stringify(dados);

        var campos = Object.keys(dados);

        var valores: any;
        valores = (<any>Object).values(dados);

        var obj = {
            tabela: tabela,
            campos: campos,
            valores: valores,
            extra: dados_2
        };

        return this.http.post(`${this.server + path}`, obj, this.getHeaders());
    }

    update_table(tabela: string, dados: any, codigo: number, path: string): Observable<ArrayBuffer>{

        dados.OPERADOR = this.profile.ID;
        dados.OBJNEW = null;
        //dados.OBJNEW = JSON.stringify(dados);

        var campos = Object.keys(dados);

        var valores: any;
        valores = (<any>Object).values(dados);

        var obj = {
            tabela: tabela,
            id: codigo,
            campos: campos,
            valores: valores
        };

        return this.http.post(`${this.server+path}`, obj, this.getHeaders());
    }

    delete_table(tabela: string, codigo: number, path: string): Observable<ArrayBuffer>{

        var obj = {
            tabela: tabela,
            id: codigo
        }

        return this.http.post(`${this.server + path}`, obj, this.getHeaders());
    }

    getPermissao (item: number): Permissao{

        return this.permissao.find(x => x.ID == item);

    }

    getPermissaoMenuPrincipal (item: number): Permissao{

        return this.permissao.find(x => x.ID_TABELA == item && x.ID_HEAD == 0);

    }

    getPermissaoTabelas (item: number): Permissao{

        return this.permissao.find(x => x.ID_TABELA == item && x.ID_HEAD != 0);

    }
    permissoes: Permissao[];

    //Esta função roda apenas no modo de desenvolvimento
    atualizaNovaPermissao(){
        this.http.get(`${this.server}users/lista`, this.getHeaders())
            .subscribe((data: any) =>{
               let users: User[] = data;

               users.forEach( x => {

                   this.permissoes = [];

                   var permissao = new Permissao();

                   permissao.ID = 1;
                   permissao.ID_HEAD = 0;
                   permissao.ID_TABELA = 1;

                   permissao.NOME = "Principal";
                   permissao.VISUALIZAR = true;
                   permissao.ALLOW_VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ALLOW_LER = false;
                   permissao.ESCREVER = true;
                   permissao.ALLOW_ESCREVER = false;
                   permissao.REMOVER = true;
                   permissao.ALLOW_REMOVER = true;
                   permissao.CRIAR = true;
                   permissao.ALLOW_CRIAR = true;

                   this.permissoes.push(permissao);

                   var permissao = new Permissao();

                   //Configurações

                   permissao.ID = 2;
                   permissao.ID_HEAD = 0;
                   permissao.ID_TABELA = 6;

                   permissao.NOME = "Configurações";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

                   this.permissoes.push(permissao);

                   var permissao = new Permissao();

                   permissao.ID = 3;
                   permissao.ID_HEAD = 0;
                   permissao.ID_TABELA = 3;

                   permissao.NOME = "Tabelas Gerais";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

                   this.permissoes.push(permissao);

                   //Fornecedor

                   var permissao = new Permissao();

                   permissao.ID = 4;
                   permissao.ID_HEAD = 3;
                   permissao.ID_TABELA = 7;

                   permissao.NOME = "Fornecedores";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

                   this.permissoes.push(permissao);

                   var permissao = new Permissao();

                   permissao.ID = 5;
                   permissao.ID_HEAD = 4;
                   permissao.ID_TABELA = 7;

                   permissao.NOME = "Financeiro";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

                   this.permissoes.push(permissao);

                   var permissao = new Permissao();

                   permissao.ID = 6;
                   permissao.ID_HEAD = 4;
                   permissao.ID_TABELA = 7;

                   permissao.NOME = "Anexos";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;


//CIDADES

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 7;
                   permissao.ID_HEAD = 3;
                   permissao.ID_TABELA = 8;

                   permissao.NOME = "Municípios";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//CARGO
                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 8;
                   permissao.ID_HEAD = 3;
                   permissao.ID_TABELA = 9;

                   permissao.NOME = "Cargos";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//CARGO

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 9;
                   permissao.ID_HEAD = 3;
                   permissao.ID_TABELA = 10;

                   permissao.NOME = "Grupos";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//CARGO (SERVICOS)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 10;
                   permissao.ID_HEAD = 3;
                   permissao.ID_TABELA = 11;

                   permissao.NOME = "Serviços";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//CLIENTES

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 11;
                   permissao.ID_HEAD = 3;
                   permissao.ID_TABELA = 12;

                   permissao.NOME = "Clientes";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//CLIENTES (SISTEMA)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 12;
                   permissao.ID_HEAD = 11;
                   permissao.ID_TABELA = 12;

                   permissao.NOME = "Sistema Cliente";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//CLIENTES (CONTATO)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 13;
                   permissao.ID_HEAD = 11;
                   permissao.ID_TABELA = 12;

                   permissao.NOME = "Contato Cliente";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//STATUS

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 14;
                   permissao.ID_HEAD = 3;
                   permissao.ID_TABELA = 14;

                   permissao.NOME = "Status Sistemas";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//FAIXA

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 15;
                   permissao.ID_HEAD = 3;
                   permissao.ID_TABELA = 15;

                   permissao.NOME = "Faixa % Administrativa";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//TAXAS

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 16;
                   permissao.ID_HEAD = 3;
                   permissao.ID_TABELA = 16;

                   permissao.NOME = "Taxa";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//USUARIO

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 17;
                   permissao.ID_HEAD = 3;
                   permissao.ID_TABELA = 17;

                   permissao.NOME = "Usuários";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//USUARIO (VER DADOS DE OUTROS)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 18;
                   permissao.ID_HEAD = 17;
                   permissao.ID_TABELA = 17;

                   permissao.NOME = "Ver Outros Usuários";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//USUARIO (RESETAR SENHA)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 19;
                   permissao.ID_HEAD = 17;
                   permissao.ID_TABELA = 17;

                   permissao.NOME = "Resetar Senha";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//USUARIO (PERMISSOES)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 20;
                   permissao.ID_HEAD = 17;
                   permissao.ID_TABELA = 17;

                   permissao.NOME = "Permissões";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//USUARIO (OUTRAS INFORMACOES)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 21;
                   permissao.ID_HEAD = 17;
                   permissao.ID_TABELA = 17;

                   permissao.NOME = "Outras Informações";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//USUARIO (ANEXOS)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 22;
                   permissao.ID_HEAD = 17;
                   permissao.ID_TABELA = 17;

                   permissao.NOME = "Anexos";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Status Anexos

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 23;
                   permissao.ID_HEAD = 3;
                   permissao.ID_TABELA = 19;

                   permissao.NOME = "Status Anexos";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Estacionamentos

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 24;
                   permissao.ID_HEAD = 3;
                   permissao.ID_TABELA = 20;

                   permissao.NOME = "Estacionamentos";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

                   this.permissoes.push(permissao);

                   //Estacionamentos (Setor)

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 25;
                   permissao.ID_HEAD = 24;
                   permissao.ID_TABELA = 20;

                   permissao.NOME = "Setores";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;


                   this.permissoes.push(permissao);

                   //Controle Armazenagem

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 26;
                   permissao.ID_HEAD = 0;
                   permissao.ID_TABELA = 18;

                   permissao.NOME = "Controle Armazenagem";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Armazenagem (Status)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 27;
                   permissao.ID_HEAD = 26;
                   permissao.ID_TABELA = 18;

                   permissao.NOME = "Status";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Armazenagem (Modelo)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 28;
                   permissao.ID_HEAD = 26;
                   permissao.ID_TABELA = 18;

                   permissao.NOME = "Modelo";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Armazenagem (Placa)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 29;
                   permissao.ID_HEAD = 26;
                   permissao.ID_TABELA = 18;

                   permissao.NOME = "Placa";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Armazenagem (Total)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 30;
                   permissao.ID_HEAD = 26;
                   permissao.ID_TABELA = 18;

                   permissao.NOME = "Total";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Armazenagem (Prestador)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 31;
                   permissao.ID_HEAD = 26;
                   permissao.ID_TABELA = 18;

                   permissao.NOME = "Prestador";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Armazenagem (Local)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 32;
                   permissao.ID_HEAD = 26;
                   permissao.ID_TABELA = 18;

                   permissao.NOME = "Local";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Armazenagem (Pátio)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 33;
                   permissao.ID_HEAD = 26;
                   permissao.ID_TABELA = 18;

                   permissao.NOME = "Pátio";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;


//Controle Armazenagem (Setor)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 34;
                   permissao.ID_HEAD = 26;
                   permissao.ID_TABELA = 18;

                   permissao.NOME = "Setor";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Armazenagem (Vaga)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 35;
                   permissao.ID_HEAD = 26;
                   permissao.ID_TABELA = 18;

                   permissao.NOME = "Vaga";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Armazenagem (Parqueamento)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 36;
                   permissao.ID_HEAD = 26;
                   permissao.ID_TABELA = 18;

                   permissao.NOME = "Parqueamento";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Armazenagem (Serviços)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 37;
                   permissao.ID_HEAD = 26;
                   permissao.ID_TABELA = 18;

                   permissao.NOME = "Serviços";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Armazenagem (Faturamento)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 38;
                   permissao.ID_HEAD = 26;
                   permissao.ID_TABELA = 18;

                   permissao.NOME = "Faturamento";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Armazenagem (Anexos)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 39;
                   permissao.ID_HEAD = 26;
                   permissao.ID_TABELA = 18;

                   permissao.NOME = "Anexos";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Veículos

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 40;
                   permissao.ID_HEAD = 0;
                   permissao.ID_TABELA = 2;

                   permissao.NOME = "Controle Transporte";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Veículos(Status)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 41;
                   permissao.ID_HEAD = 40;
                   permissao.ID_TABELA = 2;

                   permissao.NOME = "Status";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Veículos(Data Registro)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 42;
                   permissao.ID_HEAD = 40;
                   permissao.ID_TABELA = 2;

                   permissao.NOME = "Data Registro";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Veículos(Cliente)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 43;
                   permissao.ID_HEAD = 40;
                   permissao.ID_TABELA = 2;

                   permissao.NOME = "Cliente";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Veículos(Operador)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 44;
                   permissao.ID_HEAD = 40;
                   permissao.ID_TABELA = 2;

                   permissao.NOME = "Operador";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Veículos(Coleta)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 45;
                   permissao.ID_HEAD = 40;
                   permissao.ID_TABELA = 2;

                   permissao.NOME = "Coleta";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Veículos(Entrega)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();


                   permissao.ID = 46;
                   permissao.ID_HEAD = 40;
                   permissao.ID_TABELA = 2;

                   permissao.NOME = "Entrega";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Veículos(KM)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 47;
                   permissao.ID_HEAD = 40;
                   permissao.ID_TABELA = 2;

                   permissao.NOME = "KM";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Veículos(Previsão de Liberação)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 48;
                   permissao.ID_HEAD = 40;
                   permissao.ID_TABELA = 2;

                   permissao.NOME = "Previsão de Liberação";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Veículos(Data de Liberação)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 49;
                   permissao.ID_HEAD = 40;
                   permissao.ID_TABELA = 2;

                   permissao.NOME = "Data de Liberação";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Veículos(Modelos Veículos)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 50;
                   permissao.ID_HEAD = 40;
                   permissao.ID_TABELA = 2;

                   permissao.NOME = "Modelos Veículos";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Veículos(Dados Transporte)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 51;
                   permissao.ID_HEAD = 40;
                   permissao.ID_TABELA = 2;

                   permissao.NOME = "Dados Transporte";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Veículos(Faturamento)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 52;
                   permissao.ID_HEAD = 40;
                   permissao.ID_TABELA = 2;

                   permissao.NOME = "Faturamento";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Veículos(Lista Despesa)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 53;
                   permissao.ID_HEAD = 40;
                   permissao.ID_TABELA = 2;

                   permissao.NOME = "Lista Despesas";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Veículos(Cálculo Despesas)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 54;
                   permissao.ID_HEAD = 40;
                   permissao.ID_TABELA = 2;

                   permissao.NOME = "Cálculo Despesas";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Veículos(Anexos)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 55;
                   permissao.ID_HEAD = 40;
                   permissao.ID_TABELA = 2;

                   permissao.NOME = "Anexos";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Veículos(Requisição)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 56;
                   permissao.ID_HEAD = 40;
                   permissao.ID_TABELA = 2;

                   permissao.NOME = "Requisição";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Veículos(Número do Sinistro)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 57;
                   permissao.ID_HEAD = 40;
                   permissao.ID_TABELA = 2;

                   permissao.NOME = "Número Sinistro";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

//Controle Veículos(Pedido Autorizado)

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 58;
                   permissao.ID_HEAD = 40;
                   permissao.ID_TABELA = 2;

                   permissao.NOME = "Pedido Autorizado";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

                   this.permissoes.push(permissao);

                   //SEGURO

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 59;
                   permissao.ID_HEAD = 0;
                   permissao.ID_TABELA = 4;

                   permissao.NOME = "Seguro";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = false;
                   permissao.REMOVER = false;
                   permissao.CRIAR = false;

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 60;
                   permissao.ID_HEAD = 0;
                   permissao.ID_TABELA = 40;

                   permissao.NOME = "Faturamento";
                   permissao.VISUALIZAR = true;
                   permissao.LER = false;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

                   this.permissoes.push(permissao);

                   permissao = new Permissao();

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 61;
                   permissao.ID_HEAD = 3;
                   permissao.ID_TABELA = 36;

                   permissao.NOME = "Logs Sistema";
                   permissao.VISUALIZAR = false;
                   permissao.LER = false;
                   permissao.ESCREVER = false;
                   permissao.REMOVER = false;
                   permissao.CRIAR = false;

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 62;
                   permissao.ID_HEAD = 0;
                   permissao.ID_TABELA = 38;

                   permissao.NOME = "Cotação";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

                   this.permissoes.push(permissao);

                   permissao = null;
                   permissao = new Permissao();

                   permissao.ID = 63;
                   permissao.ID_HEAD = 0;
                   permissao.ID_TABELA = 39;

                   permissao.NOME = "Relatórios";
                   permissao.VISUALIZAR = true;
                   permissao.LER = true;
                   permissao.ESCREVER = true;
                   permissao.REMOVER = true;
                   permissao.CRIAR = true;

                   this.permissoes.push(permissao);

                       var resultado = JSON.stringify(this.permissoes);

                       this.update_table("USUARIO", {PERMISSAO: resultado}, x.ID, "users/atualiza")
                           .subscribe((result: any) => {
                               notify(`Usuário ${x.NOME} atualizado com sucesso`);
                           })
               });

            });
    }

}
